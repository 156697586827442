import React from 'react'
import './all.sass'
import { StaticImage } from 'gatsby-plugin-image'

/**
 * Returns a signature line.
 *
 * @param caption the signature line caption
 * @param hasDate if TRUE, Date marker will be displayed in addition to caption
 * @returns {JSX.Element} signature line component
 */
const Image = ({ caption, hasDate }) => {
  if (hasDate) {
    return (
      <div>
        <figure className="is-pulled-left has-text-left column is-full">
          <StaticImage src="../images/signatureLine.png" />
          <figcaption>
            {caption}
            <span style={{ marginLeft: '400px' }}>Date</span>
          </figcaption>
        </figure>
      </div>
    )
  }
  return (
    <div>
      <figure className="is-pulled-left has-text-left column is-full">
        <StaticImage src="../images/signatureLine.png" />
        <figcaption>{caption}</figcaption>
      </figure>
    </div>
  )
}

export default Image
