import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Table from '../components/Table'
import Signature from '../components/Signature'

export default function firmwareVerificationTestProcedure({
  data,
  pageContext,
}) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const productName = filePathParts[0]
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath}>
      <h2 className="title is-2">Overview</h2>
      <p>
        This document is a step by step procedure for firmware verification
        testing for the {productName}. The steps in this procedure were intended
        to be performed by a qualified engineer or technician. The instructions
        assume that the person performing the test has a basic understanding of
        engineering principles.
      </p>
      <p>
        These tests are not intended to be performed during production. It is
        intended to be used as a verification document for the specifications
        contained in the Software Requirements and Verification Tests
        spreadsheet.
      </p>
      <h2 className="title is-2">Test Equipment</h2>
      <Table table={frontmatter.testEquipment} />
      <p>
        Before starting any of these tests verify all testing equipment has
        current calibration labeling.
      </p>
      <h2 className="title is-2">Sampling</h2>
      <p>
        Firmware is identical across all devices, meaning results across all
        devices will have a high degree of consistency. Additionally, a firmware
        failure represents little to no risk to the patient. Using the table in
        section 5.5.3 of Validation Procedures (
        <Link to={`/BDR/Design%20Requirements/validation-test-procedure`}>
          KT #10043
        </Link>
        ), it has been determined that a sample size of 5 is sufficient for
        obtaining a 95% Confidence of Firmware functionality. As such, all
        procedures within this document require a minimum of 5 devices under
        test.
      </p>
      <h2 className="title is-2">Required Materials</h2>
      <Table table={frontmatter.requiredMaterials} />
      <h2 className="title is-2">Test #1 - {frontmatter.testNameOne}</h2>
      <h3 className="title is-3">Specifications</h3>
      <h3 className="title is-3">Test Materials</h3>
      <Table table={frontmatter.testMaterials} />
      <h3 className="title is-3">Procedure</h3>
      <p>NOTE:</p>
      <p>
        (#) – Correspond with Results Table. Should be placed when procedure
        states “Record results in (#)” [#] – Corresponds with Specification that
        is being fulfilled with that step. Occurs at the end of the step. 1.{' '}
        {frontmatter.procedureStepOne}
      </p>
      <h3 className="title is-3">Results</h3>
      <h3 className="title is-3">Signatures</h3>
      <p>Tests Performed By: {frontmatter.testPerformer}</p>
      <p>Tester Qualification: {frontmatter.testQualification}</p>
      <Signature caption="Signature" hasDate={true} />
      <h2 className="title is-2">Results Summary</h2>
      <p>Tests completed for Firmware Version: {frontmatter.firmwareVersion}</p>
      <Table table={frontmatter.resultSummaryTable} />
      <h3 className="title is-3">Signatures</h3>
      <Signature caption="Test Finalized by Date" hasDate={true} />
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryFirmwareVerificationTestProcedure($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      fields {
        filePath
      }
      frontmatter {
        templateName
        testEquipment {
          partNumber
          description
          serialNumber
          calDate
          image
        }

        requiredMaterials {
          partNumber
          description
          quantity
          image
        }

        testNameOne
        testMaterials {
          partNumber
          description
          quantity
        }

        procedureStepOne

        testPerformer
        testQualification

        firmwareVersion
        resultSummaryTable {
          number
          testDescription
          completedOn
          passed
          initials
        }
      }
    }
  }
`
